@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 180px;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;

    .title{
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      text-transform: uppercase;
      color: #7E7C7A;
    }

    h2{
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
    }
    @include media-max(1300px){
      align-items: center;
      flex-direction: column;
    }
    @include media-max(720px){
      align-items: center;
      h2 {
        font-size: 32px;
        text-align: center;
      }
    }
    @include media-max(400px){
      h2 {
        width: 250px;
      }
    }
  }

  .cards{
    display: flex;
    flex-direction: column;
    gap: 60px ;
    .card-wrapper-item{
      display: flex;
      gap:20px;
      .card-short-wrapper{
        display: flex;
        gap: 165px;
        width: 100%;

        .item{
          padding-top: 165px;
        }
        .item-image{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 165px;
          align-items: flex-end;

          .image{
            width: 124px;
            @include media-max(720px){
              width: 65px;
              height: 65px;
            }
          }
        }
        @include media-max(720px){
          flex-direction: column-reverse;
          gap: 30px;
          .item{
            padding-top: 0;
          }
          .item-image{
            align-items: inherit;
            gap: 30px;
            padding-bottom: 0;

            .image-last{
              width: 140px;
              height: 65px;
            }
          }
          .image{
            margin-left: calc(100% - 124px) !important;

          }

        }
      }
      @include media-max(1599px){
        &{
          flex-direction: column;
          align-items: center;
        .card-short-wrapper{
          justify-content: center;
        }
        }
      }

    }

    .card-wrapper-other{
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      .card-short-wrapper{
        display: flex;
        gap: 20px;

        .item-image{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;

          .image{
            margin-right: 145px;
            width: 124px;
          }
          .image-bottom{
            width: 124px;
          }
        }
        @include media-max(720px){
          width: 100%;
          flex-direction: column-reverse;
          .item-image{
            width: 100%;
          }
          .image{
            margin-right: calc(100% - 124px) !important;
            width: 65px !important;
            height: 65px;
          }
          .image-bottom{
            width: 65px !important;
            height: 65px;
          }
        }
      }
      @include media-max(1599px){
        &{
          flex-direction: column;
          align-items: center;
        }
      }
    }

  }
  @include media-max(720px){
    margin-bottom: 80px;
  }
}