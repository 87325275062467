@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.card-short{
  display: flex;
  flex-direction: column;
  border: 1px solid #EAE7DC;
  max-width: 415px;
  width: 100%;
  height: 504px;

  .image-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    background: #EFEEE6;
    .image{
      width: 317px;
      height: 317px;
    }
    .count{
      position: absolute;
      right: 20px;
      top: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      height: 100%;
      color: #E76325;
    }
  }


  .info{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 30px;

    .title-wrapper{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .title{
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
      }
      .description{
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #5B5857;
      }
      button{
        margin-top: 20px;
      }
    }
  }

  @include media-max(720px){
    flex-direction: column;
    height: 100%;
    .title-wrapper{
      .title{
        font-size: 17px !important;
      }
      .description{
        font-size: 15px !important;
        width: 100% !important;
      }
    }

    .image-wrapper{
      .image{
        width: 100%;
        height: 100%;
      }
    }

  }
}