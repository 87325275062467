@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.card-tariff{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px;
    gap: 40px;
    background: #EFEEE6;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        h4{
            font-weight: 400;
            font-size: 36px;
            line-height: 90%;
            color: #232120;
        }
        .savings{
            display: flex;
            gap: 10px;
            align-items: center;
            border: 1px solid #EAE7DC;
            padding: 5px 10px;
            span{
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #E76325;
                font-family: "ManropeRegular", sans-serif;
            }
        }
        @include media-max(720px){
            h4{
                font-size: 24px;
            }
            .savings{
                span{
                    font-size: 12px;
                }
            }
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .option{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title{
                font-weight: 400;
                font-size: 24px;
                line-height: 140%;
                color: #000000;
            }
            .description{
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #5B5857;
            }

        }
        @include media-max(720px){
            .option{
                span{
                    font-size: 14px;
                }
            }
        }
    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        h4{
            font-weight: 400;
            font-size: 36px;
            line-height: 90%;
            color: #232120;
        }

        @include media-max(720px){
            flex-direction: column;
            justify-content: inherit;
            align-items: inherit;
            h4{
                font-size: 24px;
            }
        }
    }
}