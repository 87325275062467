@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 50px;


  h1{
    font-weight: 400;
    font-size: 128px;
    line-height: 90%;
    color: #232120;
  }
  .contacts{
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  .contacts-info{
    display: flex;
    gap: 171px;
  }

  .title{
    font-weight: 400;
    font-size: 36px;
    line-height: 90%;
    color:#232120;
  }

  .info{
    display: flex;
    flex-direction: column;
    max-width: 850px;
    width: 100%;
    gap: 30px;
  }
  .email,
  .location-info,
  .phone{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    span:first-child{
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #7E7C7A;
    }
    span:last-child{
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;
      text-align: right;
      max-width: 405px;
      color:#232120;
    }

    span.email-adress{
      color:#E76325;
      padding-bottom: 8px;
      border-bottom: 1px solid #E76325;
    }
  }

  @include media-max(1100px){
    h1{
      font-size: 80px;
    }

  }
  @include media-max(720px) {
    h1 {
      font-size: 60px;
    }
    .contacts-info {
      flex-direction: column;
      gap: 30px;
    }
    .title {
      font-size: 24px;
    }
    .email,
    .location-info,
    .phone {
      flex-direction: column-reverse;
      gap: 5px;
      span:first-child {
        font-size: 14px;
      }

      span:last-child {
        font-size: 17px;
        text-align: left;
      }
    }
  }
  @include media-max(520px){
    h1{
      font-size: 48px;
    }
  }
}
.middle{
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 50px;
  border-bottom: 1px solid #EAE7DC;
  h2{
    font-weight: 400;
    font-size: 72px;
    line-height: 90%;
    color: #232120;
  }

  .map{
    width: 100%;
    height: 500px;
  }
  @include media-max(720px) {
    gap: 30px;
    h2 {
      font-size: 28px;
    }

  }
}
