@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  h2{
    font-weight: 400;
    font-size: 80px;
    line-height: 90%;
    text-align: center;
    color: #232120;
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    max-width: 1040px;
    width: 100%;

    .section{
      display: flex;
      flex-direction: column;
      gap: 30px;
      .title{
        font-weight: 400;
        font-size: 36px;
        line-height: 90%;
        color: #232120;
      }
      
      .list{
        display: flex;
        flex-direction: column;
        gap: 30px;
        
        .list-item{
          display: flex;
          gap: 30px;

          .list-ul{
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #5B5857;

            ul{
              margin: 1em 0;
              padding: 0;
              li{
                color: #E76325;
                span{
                  color: #5B5857;
                }
              }
            }
          }

          span:first-child{
            color: #E76325;
          }

          span{
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #5B5857;
          }
        }

      }
      .text{
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #5B5857;
      }
    }
  }
}
