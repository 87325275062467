@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  .content{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 1140px;
    .date{
      display: flex;
      gap: 40px;
      .date-item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        span{
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: #232120;
        }
        span:last-child{

          color: #5B5857;
        }
        .time{
          display: flex;
          align-items: center;
          gap: 10px;
          svg{
            width: 20px;
            height: 21px;
          }
        }
      }
    }
    h2{
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
      color: #232120;
    }
    p{
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #5B5857;
    }
  }
}
