@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
}
.cards{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #EAE7DC;
  
  &:last-child{
    border-bottom: none;
  }

  .card-tariff{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header{
    display: flex;
    justify-content: space-between;
    max-width: 562px;
    width: 100%;

    h4{
      font-weight: 400;
      font-size: 36px;
      line-height: 90%;
      color: #232120;
    }
    .savings{
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #EAE7DC;
      padding: 5px 10px;
      span{
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #E76325;
        font-family: "ManropeRegular", sans-serif;
      }
    }

  }
  .body{
    display: flex;
    gap: 40px;
    .option{
      display: flex;
      gap: 10px;
      align-items: center;
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #5B5857;
      }
    }
  }

  .footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    h4{
      font-weight: 400;
      font-size: 36px;
      line-height: 90%;
      color: #232120;
    }

  }

  @include media-max(1440px){
    flex-direction: column;

    .body{
      flex-direction: column;
      gap: 20px;
      padding: 40px 0;
    }
  }

  @include media-max(720px){

    .header{
      h4{
        font-size: 28px;
      }
      .savings{
        span{
          font-size: 12px;
        }
      }
    }

    .body{
      span{
        font-size: 12px;
      }
    }
    .footer{
      align-items: flex-start;
      h4 {
        font-size: 28px;
      }
    }
  }
}