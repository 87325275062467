@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.card-more{
  display: flex;
  border: 1px solid #EAE7DC;
  max-width: 850px;
  width: 100%;

  .image-wrapper{
    display: flex;
    align-items: center;
    flex: 1 1;
    background: #EFEEE6;
    .image{
      width: 385px;
      height: 385px;
    }
  }


  .info{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 30px;

    .count{
      display: flex;
      gap: 10px;
      height: 100%;

      span{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        height: 32px;
        border: 1px solid #EAE7DC;
        width: 84px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #E76325;
      }
    }
    
    .title-wrapper{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .title{
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
      }
      .description{
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #5B5857;
        width: 320px;
      }
      button{
        margin-top: 20px;
      }
    }
  }
  @include media-max(1300px){
    max-width: 100%;
  }
  @include media-max(720px){
    flex-direction: column;
    .image-wrapper{
      .image{
        width: 100% !important;
        height: 100% !important;
      }
    }
    .title-wrapper{
      .title{
        font-size: 17px !important;
      }
      .description{
        width: 100% !important;
        font-size: 14px !important;
      }
    }
  }
  }
