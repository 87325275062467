@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 180px;


  .content-container{
    justify-content: space-between;
    gap: 35px;
    display: flex;

    @include media-max(1300px){
      flex-direction: column;
      align-items: center;
    }
  }
  .image-container-top{
    display: flex;
    justify-content: center;
    align-items: center;
    .image{
      width: auto;
    }
    @include media-max(1300px){
      justify-content: flex-start;
      order: 1;
      width: 100%;
    }
    @include media-max(720px){
      .image{
        width: 65px;
        height: 100%;
      }
    }
  }
  .image-container-bottom{
    .image{
      width: auto;
    }
    @include media-max(1300px){
      display: flex;
      justify-content: flex-end;
      width: 100%;

    }
    @include media-max(720px){
        .image{
          width: 140px;
          height: 65px;
        }
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .title{
      border-bottom: 2px solid #E76325;
      color:#E76325;
      font-weight: 400;
      font-size: 128px;
      line-height: 90%;
    }
    .subtitle{
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
      text-align: center;
    }
    .text{
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      max-width: 679px;
      color: #5B5857;
    }
    @include media-max(720px){
      .title{
        font-size: 48px;
      }
      .subtitle{
        font-size: 32px;
      }
      .text{
        font-size: 14px;
      }
    }
  }

  .cards{
    display: flex;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    .card{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      border: 1px solid #EAE7DC;
      gap: 30px;
      height: 415px;
      padding: 30px;
      flex: 1 1;
      .cards-image{
        width: auto;
      }
      .cards-text{
        height: 100%;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
      }
      @include media-max(720px){
        flex-direction: row;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        .cards-text{
          font-size: 14px;
        }
      }
    }

  }

  @include media-max(720px){
    margin-bottom: 80px;
  }
}