@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.form-booking {
  border-bottom: 1px solid #EAE7DC;
  border-top: 1px solid #EAE7DC;
  padding: 60px 0;
  margin-bottom: 180px;
  @include media-max(720px){
    margin-bottom: 80px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;

  .header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #7E7C7A;
    }

    h2 {
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
    }
    @include media-max(720px){
      h2{
        font-size: 32px;
      }
      .title{
        font-size: 14px;
      }
    }
  }

.form{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  min-height: 560px;
}

  .step-title{
    font-weight: 400;
    font-size: 36px;
    line-height: 90%;
    color:#E76325;
    @include font-SegoeUI();

    span{
      color: #232120;
    }
  }

  .step-one{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .step-buttons{
      position: relative;
      height: 100%;
      background: transparent;
      border: 1px solid #EAE7DC;

      .step-button{
        padding: 30px;
        height: 100%;
        background:linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 100% 1px no-repeat,
        linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 100% / 100% 1px no-repeat,
        linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 1px 100% no-repeat,
        linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 100% 0 / 1px 100% no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

      }
    }
  }

  
  .step-two{
    display: flex;
    flex-direction: column;
    gap: 20px;


    .step-field{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 10px;
      font-family: 'ManropeRegular', sans-serif;

      .inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        .input-wrapper{

          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 10px;

        }
      }

      .button-booking{
        flex: 0 1 50%;
      }
    }

    .textarea{
      border: 1px solid #EAE7DC;
      transition: 0.3s all;
      background: transparent;
      height: 100%;
      width: 100%;
      font-family: 'ManropeRegular', sans-serif;
    }

    .buttons{
      display: flex;
      gap: 20px;

      .privacy{
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        flex: 0 1 50%;
        color: #5B5857;
        i{
          color: #E76325;
        }
        .link{
          color: #232120;
        }
      }
    }
  }
  .step-three{
    .image{
      width: 100%;
    }
  }
  @include media-max(1440px){
    .step-three{
      img{
        width: 100%;
        height: 100%;
      }

    }
    .buttons{
      flex-direction: column-reverse;
      .privacy{
        text-align: center;
        font-size: 12px !important;
      }
    }

  }
  @include media-max(970px){
    .step-title{
      font-size: 24px;
    }
  .step-button{
    height: 50vh !important;
  }
  }
}