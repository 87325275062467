@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 180px auto;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;

    .title {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #7E7C7A;
    }

    h2 {
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
      width: 736px;
      text-align: center;
    }

    @include media-max(1300px){
      align-items: center;
      flex-direction: column;
    }
    @include media-max(720px){
      h2 {
        font-size: 32px;
        width: 100%;
      }
    }
  }

  .carousel-cards {
    [data-pc-section="container"] {
      margin-bottom: 30px;
    }

    [data-pc-section="itemscontainer"] {
      gap: 20px;
    }

    [data-p-highlight="true"] {
      [data-pc-section="indicatorbutton"] {
        background: #1c1b1f;
      }

    }

    [data-pc-section="indicators"] {
      position: absolute;
      top: 100%;
      left: 43%;
      transform: translate(50%, 0);
      @include media-max(1300px) {
       &{
         display: none;
       }

      }

        path {
          stroke: #7E7C7A;
        }

      }
    [data-pc-section="previousbutton"] {
      position: absolute;
      border: 1px solid #EAE7DC;
      width: 58px;
      height: 58px;
      border-radius: 0;
      transform: rotate(180deg);
      top: 100%;

      &:hover,
      &:focus,
      &:active {
        background: #1c1b1f;

        .next-icon,
        .prev-icon {
          path {
            stroke: #fff;
          }

        }
      }
    }

    [data-pc-section="nextbutton"] {
      position: absolute;
      border: 1px solid #EAE7DC;
      width: 58px;
      height: 58px;
      border-radius: 0;
      top: 100%;
      right: 0;

      &:hover,
      &:focus,
      &:active {
        background: #1c1b1f;

        .next-icon,
        .prev-icon {
          path {
            stroke: #fff;
          }

        }
      }
    }

    .next-icon,
    .prev-icon {
      width: 12px;
      height: 10px;
    }


  }
  @include media-max(720px) {
    margin: 80px auto;
  }
}