@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;

    .title {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #7E7C7A;
      max-width: 515px;
      width: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
    }
    @include media-max(720px){
      h2 {
        font-size: 32px;
        }
      .title{
        font-size: 12px;
      }
      }

  }
  .content{
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 1rem;
    grid-auto-flow: dense;
    .image-wrapper{
      width: 100%;
      height: 100%;
    }


    .image-0{
      grid-column: span 5;
      grid-row: span 4;
    }
    .image-1{
      grid-column: span 4;
      grid-row: span 3;
    }
    .image-2{
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 6;
      grid-row-end: 7;
    }
    .image-3{
      grid-column-start:4;
      grid-column-end: 8;
      grid-row-start: 6;
      grid-row-end: 10;
    }
    .image-4{
      grid-column-start:8;
      grid-column-end: 10;
      grid-row-start: 6;
      grid-row-end: 10;
    }
    .image-5{
      grid-column-start:1;
      grid-column-end: 6;
      grid-row-start: 10;
      grid-row-end: 14;
    }
    .image-6{
      grid-column-start:6;
      grid-column-end: 10;
      grid-row-start: 10;
      grid-row-end: 14;
    }
  }
@include media-max(720px){
    .content{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .image-wrapper{
        width: 100%;
        height: 100%;
      }
      .image-0{
        padding-right: 25%;
      }

      .image-2{
        width: 30%;
        height: 30%;
      }

      .image-4{
        padding-left: 25%;
      }
      .image-6{
        padding-right: 25%;
      }
    }
}
}