@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 44px;
  margin-bottom: 180px;

  .header{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    h2{
      font-weight: 400;
      font-size: 80px;
      line-height: 90%;
      @include media-max(720px){
        font-size: 32px;
      }
    }
  }

  .cards{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(850px, 1fr) );
    gap: 60px 20px;

    @include media-max(1100px){
      grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    }
    @include media-max(720px){
      display: flex;
      flex-direction: column;
    }
  }
  @include media-max(720px){
    margin-bottom: 80px;
  }
}