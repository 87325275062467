@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.card-foot{
    display: flex;
    flex-direction: column;
    gap: 25px;

  .image{
    width: auto;
    max-height: 544px;
    transition: max-height .5s ease;
  }
  .price-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease;
    
    .prices{
      display: flex;
      gap: 30px;

      .price{
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
      }
      .other-price{
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
      }
    }
    @include media-max(720px){
      .prices{
        flex-direction: column;
        gap: 10px;
        .price{
          font-size: 19px;
        }
        .other-price{
          font-size: 14px;
        }
      }
    }
  }
  @include media-min(1101px){
    &:hover{
      .image{
        max-height: 495px;
      }
      .price-wrapper{
        max-height: 120px;
      }
      .title-wrapper{
        gap: 145px;
      }
    }
  }
  @include media-max(1100px){
    .image{
      max-height: 100%;
      height: 100%;
    }
    .price-wrapper{
      max-height: 120px;
    }
    .title-wrapper{
      gap: 145px;
    }
  }

}
.info{
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    transition:all .3s ease;

    .title{
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;
    }
    .description{
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #E76325;
    }
    .hit{
      border: 1px solid #EAE7DC;
      padding: 5px 10px;
    }
  }
  @include media-max(720px){
    .title{
      font-size: 18px !important;
    }
    .description{
      font-size: 12px !important;
    }
  }
}
