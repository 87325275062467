@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.booking{
  margin-bottom: 180px;
  margin-top: 80px;

  @include media-max(720px){
    margin-bottom: 80px;
    margin-top: 40px;
  }
}
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
  @include media-max(720px){
    gap: 25px;
  }
}
.title{
  font-weight: 400;
  font-size: 128px;
  line-height: 90%;
  text-align: center;

  @include media-max(720px){
    font-size: 48px;
  }
}

.content{
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  .image{
    padding-left: 145px;
    padding-top: 15px;
  }
  
  .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .label{
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;

      @include media-max(720px){
        font-size: 17px;
        text-align: center;
      }
    }

  }


  .photo{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @include media-max(1300px){
    &{
      justify-content: center;
      .image,
      .photo{
        display: none;
      }
    }

  }
}
.photos{
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;

  .images{
    display: flex;
    gap: 20px;
  }

  .image{
    flex: 0 1 auto;

    .img{
      width: 100%;
    }
  }
  @include media-max(1100px){
    &{

      flex-direction: column-reverse;
      .images{
        justify-content: space-between;
      }
      .image{
        img{
          height: 100%;
        }

      }
    }

  }

}